<template>
  <div>
    <v-form
      v-if="!loggedin"
      ref="form"
      lazy-validation
      @submit.prevent="submit"
    >
      <v-container>
        <v-row justify="center">
          <v-text-field
            label="username"
            name="user"
            required
            class="shrink"
          ></v-text-field>
        </v-row>
        <v-row justify="center">
          <v-text-field
            label="password"
            :type="'password'"
            name="pass"
            required
            class="shrink"
          ></v-text-field>
        </v-row>
        <v-row justify="center">
          <v-btn :disabled="buttonsubmitdisable" type="submit" color="primary"
            >تسجيل
          </v-btn>
        </v-row>
      </v-container>
    </v-form>
    <div v-if="loggedin">
      <v-btn type="submit" color="primary" @click="download">تحميل</v-btn>
    </div>
  </div>
</template>

<script>
import { getstring } from "../scripts/faculity.js";
import io from "socket.io-client";
const socket = io("https://mili-23.azurewebsites.net");
// const socket = io("https://aswu-military.azurewebsites.net");
// const socket = io("https://aswu-mili-2022.herokuapp.com/");
// const socket = io("https://aswu.herokuapp.com");

export default {
  mounted: function() {
    socket.on("result", (data) => {
      switch (data) {
        case "success":
          this.loggedin = true;
          break;
      }
      this.buttonsubmitdisable = false;
    });
    socket.on("download", (result) => {
      if (result.length) {
        const JSDateToExcelDate = (date) => {
          let d = new Date(date);
          //yyyy-MM-dd HH:mm:ss
          const dateStr =
            d.getFullYear() +
            "-" +
            ("00" + (d.getMonth() + 1)).slice(-2) +
            "-" +
            ("00" + d.getDate()).slice(-2) +
            " " +
            ("00" + d.getHours()).slice(-2) +
            ":" +
            ("00" + d.getMinutes()).slice(-2) +
            ":" +
            ("00" + d.getSeconds()).slice(-2);
          return dateStr;
        };

        const items = result.map((el) => {
          let newobject = Object.assign(el);
          if (el.sp) {
            newobject = Object.assign(getstring(el.sp), el);
          }
          delete newobject.sp;
          newobject.created = JSDateToExcelDate(newobject.created);
          newobject.ID = Number(newobject.ID);
          return newobject;
        });

        // specify how you want to handle null values here
        const replacer = (key, value) => (value === null ? "" : value);

        const header = Object.keys(items[0]);
        const csv = [
          header.join(","), // header row first
          ...items.map((row) =>
            header
              .map((fieldName) => JSON.stringify(row[fieldName], replacer))
              .join(",")
          ),
        ].join("\r\n");

        const BOM = "\uFEFF";

        const link = document.createElement("a");
        link.setAttribute(
          "href",
          "data:text/csv;charset=utf-8," + encodeURIComponent(BOM + csv)
        );
        link.setAttribute("download", "data.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".
      }
    });
  },
  data: () => ({
    buttonsubmitdisable: false,
    loggedin: false,
  }),
  methods: {
    submit: async function() {
      this.buttonsubmitdisable = true;
      const formData = new FormData(document.querySelector("form"));
      var prams = {};
      for (var pair of formData.entries()) {
        prams[pair[0]] = pair[1];
      }
      socket.emit("login", prams);
    },
    download: async function() {
      socket.emit("downloadall");
    },
  },
};
</script>
